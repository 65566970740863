import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import HomePage from './pages/Home_page';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />}></Route>
          {/* <Route exact path="/start-interview" element={<InterviewHome />}></Route>
          <Route exact path="/conversation" element={<ConversationHome />}></Route>
          <Route exact path="/video-analysis" element={<VideoAnalysis />}></Route>
          <Route exact path="/video-database" element={<VideoDatabase />}></Route>
          <Route exact path="/test-page" element={<TestPage />}></Route>
          
          <Route exact path="/interview" element={<BehaviouralInterview />}></Route>
          <Route exact path="/coding-technical-interview" element={<CodingTechnicalInterivew />}></Route> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
