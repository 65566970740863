import React from 'react';
import '../App.css'
import Home from '../LandingPage/Home';

const HomePage = () => {
    return (
        <div className='App'>
            <Home />
        </div>
    );
};

export default HomePage;
